<template>
	<div>
		<Header :title="isEdit ? '修改一卡通政策' : '添加一卡通政策'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form"
				:labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">
				<a-form-item v-if="id" label="当前影院" name="">
					{{ modelRef.cinemaName }}
				</a-form-item>
				
				<a-form-item v-if="!id" label="所属影院" name="cinemaId" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-select v-model:value="modelRef.cinemaId" placeholder="请选择所属影院" @change="onCinemaChange">
						<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item label="会员日折扣" name="cardDiscount" :rules="[{required: true, message: '必填项不允许为空'}]"
					extra="售价 =（1-折扣）* 原价">
					<a-input style="width: 200px;" v-model:value="modelRef.cardDiscount"
						placeholder="请输入会员日折扣"></a-input>
					<span style="padding-left: 6px;">
						%
					</span>
				</a-form-item>

				<a-form-item label="会员日限购" name="cardScheduleLimitNum" extra="会员日用户每日可享优惠购票次数，不配置则不做限制">
					<a-input style="width: 200px;" v-model:value="modelRef.cardScheduleLimitNum"
						placeholder="请输入会员日限购"></a-input>
					<span style="padding-left: 6px;">
						次
					</span>
				</a-form-item>

				<a-form-item label="会员单次限购" name="cardScheduleTicketLimitNum" extra="会员日用户每单可享优惠购票座位数，不配置则不做限制">
					<a-input style="width: 200px;" v-model:value="modelRef.cardScheduleTicketLimitNum"
						placeholder="请输入会员单次限购"></a-input>
					<span style="padding-left: 6px;">
						个
					</span>
				</a-form-item>

				<div>
					<a-form-item label="影片规则" name="filmType" :rules="[{required: true, message: '必填项不允许为空'}]">
						<div class="ui-flex">
							<a-radio-group v-model:value="modelRef.filmType" button-style="solid"
								@change="modelRef.filmList = [];movieList = [];">
								<a-radio-button :value="1">不限制</a-radio-button>
								<a-radio-button :value="2">指定可用影片</a-radio-button>
								<a-radio-button :value="3">指定不可用影片</a-radio-button>
							</a-radio-group>
							<span style="margin-left: 20px;" v-if="modelRef.filmType !== 1">
								<a-form-item-rest>
									<movie :id="modelRef.cinemaId" v-model:value="modelRef.filmList"
										@change="(dataList) => { movieList = dataList; }"></movie>
								</a-form-item-rest>
							</span>
						</div>
						<div v-if="movieList.length" style="margin: 10px 0;">
							<a-table rowKey="id" :columns="movieColumns" :dataSource="movieList" size="small"
								:pagination="false">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'action'">
										<a-button type="link" @click="onDeleteMovie(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item>

					<a-form-item label="影厅规则" name="hallType" :rules="[{required: true, message: '必填项不允许为空'}]">
						<div class="ui-flex">
							<a-radio-group v-model:value="modelRef.hallType" button-style="solid"
								@change="modelRef.hallList = [];hallDataList = [];">
								<a-radio-button :value="1">不限制</a-radio-button>
								<a-radio-button :value="2">指定可用影厅</a-radio-button>
								<a-radio-button :value="3">指定不可用影厅</a-radio-button>
							</a-radio-group>
							<span style="margin-left: 20px;" v-if="modelRef.hallType !== 1">
								<a-form-item-rest>
									<cinema :id="modelRef.cinemaId" v-model:value="modelRef.hallList"
										@change="(dataList) => {hallDataList = dataList}"></cinema>
								</a-form-item-rest>
							</span>
						</div>
						<div v-if="hallDataList.length" style="margin: 10px 0;">
							<a-table rowKey="id" :columns="hallColumns" :dataSource="hallDataList" size="small"
								:pagination="false">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'action'">
										<a-button type="link" @click="onDeleteHall(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item>

					<a-form-item label="视觉规则" name="visualType" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-radio-group v-model:value="modelRef.visualType" button-style="solid">
							<a-radio-button :value="1">不限制</a-radio-button>
							<a-radio-button :value="2">指定可用视觉</a-radio-button>
							<a-radio-button :value="3">指定不可用视觉</a-radio-button>
						</a-radio-group>
					</a-form-item>

					<a-form-item v-if="modelRef.visualType !== 1" label="选择视觉" name="visualList"
						:rules="[{required: true, message: '必选项不允许为空'}]">
						<a-form-item-rest>
							<a-checkbox v-model:checked="checkAll" :indeterminate="indeterminate"
								@change="onCheckAllChange">全选</a-checkbox>
						</a-form-item-rest>
						<a-checkbox-group v-model:value="modelRef.visualList">
							<a-checkbox :value="1">2D</a-checkbox>
							<a-checkbox :value="2">3D</a-checkbox>
							<a-checkbox :value="3">4D</a-checkbox>
							<a-checkbox :value="4">IMAX2D</a-checkbox>
							<a-checkbox :value="5">IMAX3D</a-checkbox>
						</a-checkbox-group>
					</a-form-item>

					<a-form-item label="分区规则" name="partitionType" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-radio-group v-model:value="modelRef.partitionType" button-style="solid">
							<a-radio-button :value="1">不限制</a-radio-button>
							<a-radio-button :value="2">指定可用分区</a-radio-button>
							<a-radio-button :value="3">指定不可用分区</a-radio-button>
						</a-radio-group>
					</a-form-item>
					<a-form-item v-if="modelRef.partitionType !== 1" label="选择分区" name="partitionList"
						:rules="[{required: true, message: '必选项不允许为空'}]">
						<a-form-item-rest>
							<a-checkbox v-model:checked="partitionCheckAll" :indeterminate="indeterminatePartition"
								@change="onPartitionCheckAllChange">全选</a-checkbox>
						</a-form-item-rest>
						<a-checkbox-group v-model:value="modelRef.partitionList">
							<a-checkbox v-for="item in partitionAllList" :key="item.id"
								:value="item.id">{{ item.name }}</a-checkbox>
						</a-checkbox-group>
					</a-form-item>

					<a-form-item label="会员日设置">
						<div class="ui-time__header">
							<span class="ui-time__span">日期</span>
							<a-range-picker v-model:value="modelRef.date"></a-range-picker>
							<a class="ui-time__span" @click="onAddSet">
								<Icon icon="PlusOutlined" style="margin-right: 2px;"></Icon>添加适用时间段
							</a>
						</div>
						<div class="ui-time__set" v-for="(item,index) in modelRef.timeList" :key="index">
							<div>
								<span class="ui-time__span">周期</span>
								<a-checkbox-group v-model:value="item.week">
									<a-checkbox :value="7">周日</a-checkbox>
									<a-checkbox :value="1">周一</a-checkbox>
									<a-checkbox :value="2">周二</a-checkbox>
									<a-checkbox :value="3">周三</a-checkbox>
									<a-checkbox :value="4">周四</a-checkbox>
									<a-checkbox :value="5">周五</a-checkbox>
									<a-checkbox :value="6">周六</a-checkbox>
								</a-checkbox-group>
							</div>
							<div class="ui-margin__top20">
								<span class="ui-time__span">时段</span>
								<a-time-picker format="HH:mm" v-model:value="item.startTime" />
								<span class="ui-time__span">至</span>
								<a-time-picker format="HH:mm" v-model:value="item.endTime" />
								<a class="ui-time__span" @click="onDelSetting(index)">
									<Icon icon="DeleteOutlined"></Icon> 删除
								</a>
							</div>
						</div>
					</a-form-item>
				</div>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	// import dayjs from 'dayjs';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import movie from '@/components/movie/index.vue';
	import cinema from '@/components/cinema/index.vue';
	import {
		getPricePolicyDetail,
		savePricePolicy,
		updatePricePolicy
	} from '@/service/modules/cinema.js';
	import {
		getCinemaAllList,
		getAllCinemaPartitionList
	} from "@/service/modules/cinema.js";
	import {
		getUnionCardPolicyDetail,
		updateUnionCardPolicy,
		saveUnionCardPolicy
	} from "@/service/modules/coupon";
	export default {
		components: {
			Icon,
			Header,
			movie,
			cinema
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
		},
		created() {
			if (this.isEdit && this.id) {
				this.getData();
				this.getAllCinemaPartitionList();
			} else {
				this.getAllCinemaList();
			}
		},
		data() {
			return {
				loading: false,
				isRead: false,
				cinemaAllList: [],
				partitionAllList: [],
				modelRef: {
					pricingLevel: 0,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					visualType: 1,
					partitionType: 1,
					visualList: [],
					partitionList: [],
					timeList: [],
				},
				readVisible: false,
				readTime: 3,
				checkAll: false,
				partitionCheckAll: false,
				indeterminate: false,
				indeterminatePartition: false,
				movieList: [],
				movieColumns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '影片名称',
					dataIndex: 'shortName'
				}, {
					title: '语言',
					dataIndex: 'language',
					width: 80,
					align: 'center'
				}, {
					title: '操作',
					key: 'action'
				}],
				hallDataList: [],
				hallColumns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '影厅名称',
					dataIndex: 'name'
				}, {
					title: '操作',
					key: 'action'
				}]
			}
		},
		watch: {
			'modelRef.visualList'(newVal) {
				this.indeterminate = !!newVal.length && newVal.length < 5;
				this.checkAll = newVal.length === 5;
			},
			'modelRef.partitionList'(newVal) {
				this.indeterminatePartition = !!newVal.length && newVal.length < this.partitionAllList.length;
				this.partitionCheckAll = newVal.length === this.partitionAllList.length;
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getUnionCardPolicyDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						if (ret.data.startDate) {
							ret.data.startDate = this.moment(ret.data.startDate * 1000);
						} else {
							ret.data.startDate = undefined;
						}
						if (ret.data.endDate) {
							ret.data.endDate = this.moment(ret.data.endDate * 1000);
						} else {
							ret.data.endDate = undefined;
						}
						ret.data.filmList = ret.data.filmList ? ret.data.filmList.split(',') : [];
						ret.data.hallList = ret.data.hallList ? ret.data.hallList.split(',') : [];
						ret.data.visualList = ret.data.dimensionalList ? ret.data.dimensionalList.split(',').map(item => Number(item)) : [];
						ret.data.partitionList = ret.data.partitionList ? ret.data.partitionList.split(',').map(item => Number(item)) : [];
						ret.data.timeList = JSON.parse(ret.data.timeList);
						if (ret.data.timeList) {
							ret.data.timeList.forEach(item => {
								let startTime = this.moment.duration(Number(item.startTime), 'second').hours() + ':' + this.moment.duration(Number(item.startTime), 'second').minutes();
								let endTime = this.moment.duration(Number(item.endTime), 'second').hours() + ':' + this.moment.duration(Number(item.endTime), 'second').minutes();
								item.week = item.week.split(',').map(item => Number(item));
								item.startTime = this.moment(startTime, 'HH:mm');
								item.endTime = this.moment(endTime, 'HH:mm');
							});
						}
						this.movieList = ret.data.cinemaFilmList ? ret.data.cinemaFilmList.map(item => {
							item.id = item.filmId.toString();
							item.standardCode = item.filmCode;
							item.shortName = item.film;
							return item;
						}) : [];
						this.hallDataList = ret.data.cinemaHallList ? ret.data.cinemaHallList.map(item => {
							item.id = item.hallId.toString();
							item.name = item.hall;
							item.code = item.hallCode;
							return item;
						}) : [];
						delete ret.data.cinemaFilmList;
						delete ret.data.cinemaHallList;

						this.modelRef = ret.data;

						if (this.modelRef.startDate && this.modelRef.endDate) {
							const timeList = [];
							timeList.push(this.modelRef.startDate);
							timeList.push(this.modelRef.endDate);
							this.modelRef.date = timeList;
						}
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaPartitionList() {
				this.loading = true;
				try {
					let ret = await getAllCinemaPartitionList({
						cinemaId: this.modelRef.cinemaId
					})
					this.loading = false;
					if (ret.code === 200) {
						this.partitionAllList = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				if (postData.filmType !== 1 && postData.filmList.length === 0) {
					this.$message.error('影片列表不能为空，请选择影片！')
					return;
				}
				if (postData.hallType !== 1 && postData.hallList.length === 0) {
					this.$message.error('影厅列表不能为空，请选择影厅！');
					return;
				}
				postData.filmList = postData.filmList.join(',');
				postData.hallList = postData.hallList.join(',');
				postData.dimensionalList = postData.visualList.join(',');
				postData.partitionList = postData.partitionList.join(',');
				if (this.modelRef.date && this.modelRef.date.length === 2) {
					postData.startDate = this.moment(this.modelRef.date[0].startOf('day')).unix();
					postData.endDate = this.moment(this.modelRef.date[1].endOf('day')).unix();
				}
				if (postData.timeList) {
					postData.timeList = postData.timeList.map((item) => {
						return {
							week: item.week.join(','),
							startTime: this.moment(item.startTime).get('hour') * 60 * 60 + this.moment(item.startTime).get('minute') * 60,
							endTime: this.moment(item.endTime).get('hour') * 60 * 60 + this.moment(item.endTime).get('minute') * 60
						}
					});
				}

				try {
					this.loading = true;
					let ret
					if (this.isEdit && this.id) {
						ret = await updateUnionCardPolicy(postData);
					} else {
						ret = await saveUnionCardPolicy(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onAddSet() {
				if (!this.modelRef.timeList) this.modelRef.timeList = [];
				this.modelRef.timeList.push({
					week: [7, 1, 2, 3, 4, 5, 6],
					startTime: this.moment('00:00', 'HH:mm'),
					endTime: this.moment('23:59', 'HH:mm')
				});
			},
			onDelSetting(index) {
				this.modelRef.timeList.splice(index, 1);
			},
			timeCountDown() {
				if (this.readTime > 0) {
					setTimeout(() => {
						this.readTime -= 1;
						this.timeCountDown();
					}, 1000);
				}
			},
			onCheckAllChange(e) {
				if (e.target.checked) {
					this.modelRef.visualList = [1, 2, 3, 4, 5];
				} else {
					this.modelRef.visualList = [];
				}
			},
			onPartitionCheckAllChange(e) {
				if (e.target.checked) {
					this.partitionAllList.forEach(item => {
						this.modelRef.partitionList.push(item.id);
					})
				} else {
					this.modelRef.partitionList = [];
				}
			},
			onDeleteMovie(item, index) {
				let i = this.modelRef.filmList.indexOf(item.id);
				this.modelRef.filmList.splice(i, 1);
				this.movieList.splice(index, 1);
			},
			onDeleteHall(item, index) {
				let i = this.modelRef.hallList.indexOf(item.id);
				this.modelRef.hallList.splice(i, 1);
				this.hallDataList.splice(index, 1);
			},
			onCinemaChange() {
				this.modelRef.filmList = [];
				this.movieList = [];
				this.modelRef.hallList = [];
				this.hallDataList = [];
				this.modelRef.partitionList = [];
				this.getAllCinemaPartitionList();
			}
		}
	}
</script>

<style scoped>
	.ui-flex {
		display: flex;
	}

	.ui-input {
		width: 180px;
		display: inline-block;
	}

	.ui-margin__top20 {
		margin-top: 20px;
	}

	.ui-margin__top40 {
		margin-top: 40px;
	}

	.ui-time__header {
		padding: 10px;
		color: rgba(0, 0, 0, .65);
		background-color: #f5f5f5;
	}

	.ui-time__span {
		padding: 0 10px;
	}

	.ui-time__set {
		padding: 20px 10px;
		border-bottom: solid 1px #eee;
		border-left: solid 1px #eee;
		border-right: solid 1px #eee;
	}
</style>